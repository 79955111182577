// import tracking from '@finn-no/analytics';
import pulse from '@finn-no/pulse-sdk';
import getTrackingMetadata from './tracking-metadata';

function trackPage() {
    const trackingMetaData = getTrackingMetadata(document.location.pathname);

    pulse.trackPageView({
        object: {
            type: 'Article',
            category: trackingMetaData.category,
            tags: trackingMetaData.tags,
        },
        vertical: {
            name: trackingMetaData.vertical,
            subVertical: trackingMetaData.subVertical,
        },
    });

    const huliiLinkListener = (e) => {
        // stop the execution of the navigation
        e.preventDefault();
        // send the tracker event
        pulse.trackEvent({
            type: 'Click',
            intent: 'Open',
            name: 'Refurbished electronics',
            object: {
                id: 'sjekka-brukt-mobil',
                type: 'UIElement',
                name: '',
                element: 'link',
                page: {
                    id: 'ref',
                    url: '',
                    type: 'Article',
                },
            },
            target: {
                id: e.target.href,
                type: 'ExternalContent',
                name: 'Go to webpage',
                referralCommission: false,
                provider: 'hulii',
            },
            vertical: {
                name: trackingMetaData.vertical,
                subVertical: trackingMetaData.subVertical,
            },
        });
        // wait for the queue to clear before moving on
        let numTries = 0;
        const intervalId = window.setInterval(() => {
            numTries += 1;
            pulse.getTracker((t) => {
                if (!t.eventQueue.length || numTries > 9) {
                    window.clearInterval(intervalId);
                    window.location.href = e.target.href;
                }
            });
        }, 60);
    };

    const downloadPdfListener = (e, pdfUrl) => {
        // get the name of the PDF
        const pdfUrlParts = pdfUrl.split('/');
        const pdfName = pdfUrlParts[pdfUrlParts.length - 1];

        // stop the execution of the navigation
        e.preventDefault();
        // send the tracker event
        pulse.trackEvent({
            type: 'Click',
            intent: 'Download',
            name: pdfName,
            object: {
                id: 'download-pdf-button',
                type: 'UIElement',
                elementType: 'Button',
                page: {
                    id: window.location.href,
                    type: 'Article',
                    url: window.location.href,
                },
            },
        });
        // wait for the queue to clear before moving on
        let numTries = 0;
        const intervalId = window.setInterval(() => {
            numTries += 1;
            pulse.getTracker((t) => {
                if (!t.eventQueue.length || numTries > 9) {
                    window.clearInterval(intervalId);
                    window.location.href = pdfUrl;
                }
            });
        }, 60);
    };

    // get a reference to all links
    const huliiLink = document.querySelectorAll(
        '[href^="https://shop.hulii.no/"]',
    );
    // add a listener if the links exists
    if (huliiLink && huliiLink.length) {
        huliiLink.forEach((h) => {
            h.addEventListener('click', huliiLinkListener, false);
        });
    }

    // get reference to all download PDF links
    const downloadPdfLink = document.querySelectorAll(
        '[href^="https://finn-content-hub.s3.amazonaws.com/"]',
    );
    // add a listener if the link exists
    if (downloadPdfLink && downloadPdfLink.length) {
        downloadPdfLink.forEach((d) => {
            d.addEventListener(
                'click',
                (e) => downloadPdfListener(e, d.href),
                false,
            );
        });
    }
}

document.addEventListener('DOMContentLoaded', trackPage);
